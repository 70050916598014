<template>
    <div id="data-view">
        <!-- <dv-full-screen-container> -->
        <!-- <div style="height:10%;" class="ms-title">
            
        </div> -->
        <div style="display:flex;">
            <div style="width:30%;margin-left:40px;">
            
                <dv-border-box-1 style="width:450px;height:340px;text-align:center;">
                    <div style="height:25px;"></div>
                    <dv-conical-column-chart :config="config" style="width:400px;height:200px;margin-left:25px;" />
                    
                </dv-border-box-1>
                <!-- <dv-border-box-1 style="width:400px;height:300px"> -->
                <dv-border-box-1 style="width:450px;height:280px;text-align:center;">城市级别资源量占比分布
                    <dv-active-ring-chart :config="cityLevel"  style="width:100%;height:100%;" /></dv-border-box-1>
                <!-- </dv-border-box-1> -->
                <dv-border-box-1 style="width:450px;height:280px;text-align:center;">地区资源量占比分布
                <dv-active-ring-chart :config="area"  style="width:100%;height:100%; " /></dv-border-box-1>
            </div>
            <div style="width:40%;margin-left:-40px;">
                <!-- <div style="margin-top:10px;"  class="ms-title"><dv-decoration-11 style="width:500px;height:95px;">TEM接触点效益智能管理系统</dv-decoration-11></div> -->
               
                    
                
                <div id="digital-flop" >
                    <div  class="digital-flop-item"  v-for="item in numData" :key="item.title">
                    <div class="digital-flop-title">{{ item.title }}</div>
                    <div class="digital-flop">
                        <dv-digital-flop :config="item.number" style="width:100px;height:50px;"/>
                        <div class="unit">{{ item.unit }}</div>
                    </div>
                    </div>
                    <dv-decoration-10 />
                </div>
                <div style="height:500px;" id="gaugeChart">
                   
                    <!-- <dv-charts :option="indexOption" /> -->
                </div>
                <div style="margin-top:-0px;">
                    <line-chart ref="monthChart" :obj-name="'monthChart'" @onchange="onchange"/>   
                </div>

            </div>
            <div style="width:30%;margin-left:20px;">
                <dv-border-box-1 style="width:450px;height:340px;text-align:center;">大区销售方案排名
                <dv-scroll-ranking-board :config="paiming" style="width:360px;height:85%;margin-left:20px;" /></dv-border-box-1>
                <!-- <div class="sectitle">资源销售贡献</div> -->
                <dv-border-box-1 style="width:450px;height:280px;text-align:center;">上刊率比重分布
                <dv-active-ring-chart :config="saleData" style="width:100%;height:100%;" /></dv-border-box-1>
                <dv-border-box-1 style="width:450px;height:280px;text-align:center;">客户行业占比
                <dv-active-ring-chart :config="industryData" style="width:100%;height:100%;" /></dv-border-box-1>
            </div>
             <div class="navbar-rbox" style="position:fixed;bottom:60px;right:8px;">
                    <div class="nav-weather flex-row align-items-center" style="color: #32C5E9;" @click="goback">
                        >>
                    </div>
                </div>
        </div>
        <!-- <div class="background">
            <img :src="imgSrc" width="100%" height="100%" alt=""/>
        </div>
        <div class="front" style="height:100%;">
            <div class="ms-title">TEM接触点效益智能管理系统</div>

                <div style="display:flex;justify-content:space-between;align-items:center;height: 100%;margin-top: -20px;">
                    <div style="width:31%;" @click="goto(1)"><el-image style="height:400px;" fit="cover" :src="require('@/assets/img/zuozhan.jpg')"></el-image></div>
                    <div style="width:31%;" @click="goto(2)"><el-image style="height:400px;" fit="cover" :src="require('@/assets/img/zhihui.jpg')"></el-image></div>
                    <div style="width:31%;" @click="goto(3)"><el-image style="height:400px;" fit="cover" :src="require('@/assets/img/danyao.jpg')"></el-image></div>
                </div>

        </div> -->
        <!-- </dv-full-screen-container> -->
        
        <div class="footer">
            <div class="footbox">
                <div class="tit">最新公告</div>
                <div class="con">
					<!-- <marquee class="noticeText ng-binding" direction="left" behavior="scroll" scrollamount="100"
							scrolldelay="1000" loop="" width="100%" style="width: 100%;">
						<ul>
							<li v-for="item in messageList" :key="item.id"><b class="c1">{{item.id}}</b>{{item.title}}</li>
						</ul>
					</marquee> -->

                    <marquee id="affiche" class="noticeText ng-binding" direction="left" behavior="scroll" bgcolor="#000"
                        width="100%" hspace="1000" vspace="20" loop="-1" scrollamount="100" scrolldelay="1000"
                        onMouseOut="this.start()" onMouseOver="this.stop()">
                        <ul>
							<li v-for="item in messageList" :key="item.id"><b :class="item.type=='资源'?'c1':(item.type=='预警'?'c2':(item.type=='产品包'?'c4':'c3'))">{{item.type}}</b>{{item.title}}</li>
						</ul>
                    </marquee>

                </div>
            </div>
        </div>
       
    </div>
</template>


<script>
import LineChart from "@/components/LineChart_Index";
import {createLineData} from "@/utils/data-generator";
import echarts from  "echarts";


export default {
    components: {
        LineChart
    },

    data() {
        return {
            imgSrc:require('@/assets/img/shouye.jpg'),
            messageList:[
                {id:1,type:"资源",title:'广州南 GZS-L-26已恢复，可上刊'}                  
                ,{id:2,type:"产品包",title:'《百城百站》 上线'}
                ,{id:3,type:"预警",title:'北京南 BJS-L-27 将于2022.6.10到期'}
                ,{id:4,type:"资源",title:'天津西 北进站口疫情关闭，开通将另行通知，纳入到套餐1中'}
                ,{id:5,type:"车站",title:'西安北 未建好，暂不能上刊'}
                
                
            ],

            config:{
                data: [
                    {name: 'LED',value: 734},
                    {name: '灯箱',value: 1209},
                    {name: '数字灯箱',value: 23},
                    {name: '列车',value: 66},
                    {name: '12306',value: 55},
                ],
                img: [
                    require('@/assets/img/led.jpg'),
                    require('@/assets/img/dx.jpg'),
                    require('@/assets/img/szdx.jpg'),
                    require('@/assets/img/hc.jpg'),
                    require('@/assets/img/12306.jpg'),
                ],
                showValue: false
            },
            
            numData : [
                {
                    title: '覆盖城市',
                    number: {
                        number: [204],
                        content: '{nt}',
                        textAlign: 'center',
                        style: {
                        fill: '#40faee',
                        fontWeight: 'bold'
                        }
                    },
                    unit: '个'
                },
                {
                    title: '资源总数',
                    number: {
                        number: [2036],
                        content: '{nt}',
                        textAlign: 'center',
                        style: {
                        fill: '#f46827',
                        fontWeight: 'bold'
                        }
                    },
                    unit: '个'
                },
                {
                    title: '产品包总数',
                    number: {
                        number: [9],
                        content: '{nt}',
                        textAlign: 'center',
                        style: {
                        fill: '#40faee',
                        fontWeight: 'bold'
                        }
                    },
                    unit: '组'
                },
              ],

            cityLevel:{
                radius: '60%',
                activeRadius: '65%',
                data: [
                    {
                    name: '一线城市',
                    value: 542
                    },
                    {
                    name: '二线城市',
                    value: 687
                    },
                    {
                    name: '三线城市',
                    value: 349
                    },
                    {
                    name: '四线城市',
                    value: 403
                    },
                    {
                    name: '其他',
                    value: 23
                    }
                ],
                digitalFlopStyle: {
                    fontSize: 20
                }
            },

            area:{
                radius: '60%',
                activeRadius: '65%',
                data: [
                    {
                    name: '华中大区',
                    value: 376
                    },
                    {
                    name: '华东大区',
                    value: 682
                    },
                    {
                    name: '西北大区',
                    value: 39
                    },
                    {
                    name: '东南大区',
                    value: 132
                    },
                    {
                    name: '西南大区',
                    value: 11
                    },
                    {
                    name: '东北大区',
                    value: 54
                    },
                    {
                    name: '华南大区',
                    value: 148
                    },
                    {
                    name: '华北大区',
                    value: 562
                    }
                ],
                digitalFlopStyle: {
                    fontSize: 20
                }
            },

            paiming:{
                data: [
                {
                name: '东北大区',
                value: 55
                },
                {
                name: '西南大区',
                value: 120
                },
                {
                name: '西北大区',
                value: 78
                },
                {
                name: '华北大区',
                value: 66
                },
                {
                name: '华东大区',
                value: 80
                },
                {
                name: '华东大区',
                value: 45
                },
                {
                name: '华中大区',
                value: 29
                }
                ]
            },

            saleData:{
                radius: '60%',
                activeRadius: '65%',
                data: [
                    {
                    name: '0~10%',
                    value: 37
                    },
                    {
                    name: '10~20%',
                    value: 26
                    },
                    {
                    name: '20~80%',
                    value: 38
                    },
                    {
                    name: '80~90%',
                    value: 0
                    },
                    {
                    name: '90%以上',
                    value: 0
                    },
                    
                ],
                digitalFlopStyle: {
                    fontSize: 20
                }
            },
            
            industryData:{
                radius: '60%',
                activeRadius: '65%',
                data: [
                    {
                    name: '酒类',
                    value: 47
                    },
                    {
                    name: '食品快消',
                    value: 17
                    },
                    {
                    name: '家居建材',
                    value: 16
                    },
                    {
                    name: '其他',
                    value: 15
                    },
                    {
                    name: '文化旅游',
                    value: 2
                    },
                    {
                    name: '厨具',
                    value: 0
                    }
                ],
                digitalFlopStyle: {
                    fontSize: 20
                }
            },

            indexOption:{
                  title: {
                    text: '当月平均上刊率',
                    style: {
                        fill: '#fff'
                    },
                    offset:[0,230],//标题位置偏移
                },
                series: [
                    {
                    type: 'gauge',
                    data: [ 
                        { name: 'itemA', value: 19.5 }
                        // { // 每个对象都代表一个指针
                        //     value:19.5,
                        //     itemStyle:{
                        //         color:'red',

                        //     }
                        // }, 
                        // {
                        //     value:14.3,
                        //     itemStyle:{
                        //         color:'green'
                        //     }
                        // },
                    ],
                    min:0,
                    max: 100,
                    
                    radius: '75%',
                    center: ['50%', '47%'],
                    axisLabel: {
                        formatter: '{value}%',
                        style: {
                            fill: '#fff'
                        }
                    },
                    axisTick: {
                        style: {
                        stroke: '#fff'
                        }
                    },
                    animationCurve: 'easeInOutBack',
                    detail: {//仪表盘详情数据相关
			            textStyle: {
		                    color: '#5bdbff',
		                    fontSize:46,
		                    // offsetCenter: [0,'80%']
		               }
			        },
			        itemStyle:{//指针样式
			            normal:{
			                color:'#000'
			            }
			        },
			        pointer:{//指针长度与宽度
			            width:28,
			            length:'585%'
			        },
                    }
                ]
            },

        };
    },
    watch: {},
    $route: {

    },
    async mounted(){
        var list=[{label:'1月',value:0},{label:'2月',value:0},{label:'3月',value:0},{label:'4月',value:0},{label:'5月',value:19.5}];
        this.drawGauge();
        // await this.$store.dispatch('analysis/Analisys', {userId: getToken(),sDate,eDate}).then(res=>{
        this.$refs.monthChart.initChart('全国资源平均上刊率月度变化趋势','销售额',createLineData({data:list}))
                // this.onDrawSubTable();
                
        // });
    },

    methods: {
        drawGauge(){
            var gaugeChart = echarts.init(document.getElementById('gaugeChart'));

            const gaugeData = [
            {
                value: 19.5,
                name: '当月平均上刊率',
                title: {
                    offsetCenter: ['-40%', '80%'],                    
                },
                detail: {
                    offsetCenter: ['-40%', '95%'],
                    
                }
            },
            // {
            //     value: 14.3,
            //     name: 'Better',
            //     title: {
            //     offsetCenter: ['-20%', '80%']
            //     },
            //     detail: {
            //     offsetCenter: ['-20%', '95%']
            //     }
            // }
            ];
            
	     
	        var option =
                 {
                    series: [
                        {
                        type: 'gauge',
                        anchor: {
                            show: true,
                            showAbove: true,
                            size: 18,
                            itemStyle: {
                            color: '#FAC858'
                            }
                        },
                        pointer: {
                            icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
                            width: 8,
                            length: '80%',
                            offsetCenter: [0, '8%']
                        },
                        progress: {
                            show: true,
                            overlap: true,
                            roundCap: true
                        },
                        axisLine: {
                            roundCap: true
                        },
                        data: gaugeData,
                        title: {
                            fontSize: 14,
                            color:'#ccc',
                        },
                        detail: {
                            width: 40,
                            height: 14,
                            fontSize: 14,
                            color: '#fff',
                            backgroundColor: 'auto',
                            borderRadius: 3,
                            formatter: '{value}%'
                        }
                        }
                    ]
            };



            gaugeChart.setOption(option);
        },

       goto(flag){
        //    this.$router.push({name: 'login'})
        var title='';
        switch(flag){
            case 1:
                title='数据赋能--作战系统';
                break;
            case 2:
                title='数据赋能--指挥系统';
                break;
            default:
                title='数据赋能--管理系统';
                break;
        }
        this.$store.commit("setSysTitle", title);
        this.$router.push({name: 'Login2', params: {}})
       },

       async goback(){
            // await this.$store.dispatch("Business/Alert", {     });
            // this.$router.go(-1);
            this.$router.push({path: '/Business/Alert', query: this.otherQuery})
        },
        
    }
};
</script>

<style lang="less">
#data-view {
    width: 100%; height: calc(100% - 0px);
//   width: 100%;
//   height: 100%;
  background-color: #030409;
  color: rgb(255, 255, 255);
//   z-index: 999;

  #dv-full-screen-container {
    background-image: require('@/assets/img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }



  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
    width: 80%;
  }

  .block-top-content {
    height: 55%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
  }
}


.sectitle {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    // margin-left:140px;
    margin-top:-0px;
  }

  #digital-flop {
  position: relative;
  height: 15%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);

  .dv-decoration-10 {
    position: absolute;
    width: 95%;
    left: 2.5%;
    height: 5px;
    bottom: 0px;
  }

  .digital-flop-item {
    width: 33%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid rgb(6, 30, 93);
    border-right: 3px solid rgb(6, 30, 93);
  }

  .digital-flop-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .digital-flop {
    display: flex;
  }

  .unit {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 13px;
  }
  }

    .ms-title{
        display:flex;
        justify-content: center;
        font-size: 50px;
        height:100px;
        align-items: center;
        margin-top: 20px;
    }

    // .background{
    //     background: url("../assets/img/shouye.jpg") no-repeat;
    //     background-position: center;
    //     height: 100%;
    //     width: 100%;
    //     background-size: cover;
    //     position: fixed;
    // }
    // .front{
    //     z-index: 1;
    //     position:absolute;
    // }
    .footer { position:fixed; left:0; bottom:0; width:100%; height:49px; border-top:1px #142b68 solid; background:#0f1942; z-index:999;}
.footbox { position:relative; padding-left:101px;}
.footbox .tit { position:absolute; left:0; top:0; background:#112051; height:49px; line-height:49px; text-align:center; font-size:16px; color:#0ec5fb; font-weight:bold; width:100px; border-right:1px #183a88 solid; }
// .footbox .con { height:49px; background:url(../images/hbg.png) left top no-repeat;}
.footbox .con ul li{ float:left; width:30%; height:49px; overflow: auto; font-size:12px; line-height:49px; color:#aad4ff; margin:0 1%;}
.footbox .con ul li b{ display:inline-block; width:48px; height:18px; line-height:18px; border-radius:3px; text-align:center; font-size:12px; color:#fff; position:relative; margin-right:11px; vertical-align:middle; font-weight:normal; margin-bottom:3px; position:relative;}
.footbox .con ul li b:after{ display:block; content:''; width:0; height:0; border-top:4px solid transparent; border-bottom:4px solid transparent; position:absolute; right:-4px; top:5px;}
.footbox .con ul li b.c1{ background:#ffbf1f; color:#333333;}
.footbox .con ul li b.c1:after{ border-left:4px solid #ffbf1f;}
.footbox .con ul li b.c2{ background:#ec4747;}
.footbox .con ul li b.c2:after{ border-left:4px solid #ec4747;}
.footbox .con ul li b.c3{ background:#41aaf2;}
.footbox .con ul li b.c3:after{ border-left:4px solid #41aaf2;}
.footbox .con ul li b.c4{ background:#65c548;}
.footbox .con ul li b.c4:after{ border-left:4px solid #65c548;}
.footbox .con ul li b.c5{ background:#ab40db;}
.footbox .con ul li b.c5:after{ border-left:4px solid #ab40db;}
    
</style>